import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  infoUser :[]
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'INFO_USUARIO':
        return {...state,
          infoUser: rest.infoUser}
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
